import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"
import styles from "../../../components/header.module.css"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import classnames from "classnames";
import { window } from 'browser-monads';
import disableScroll from 'disable-scroll';
import { Icon } from 'react-icons-kit'
import {search} from 'react-icons-kit/fa/search'
import NonUkResidentsNonHcpDisclaimerPopup from "../../prompts/disclaimers/nonUkResidents/nonHcp/disclaimer-popup"
import NonUkResidentsNonHcpApplyOnlinePopup from "../../prompts/careers/nonUkResidents/nonHcp/apply-online"
import NonUkResidentsNonHcpEmailFriendPopup from "../../prompts/careers/nonUkResidents/nonHcp/email-friend"
import FranceCaptchaPopup from "../../prompts/shared/france-captcha-popup"
import {showFranceCaptchaPopup} from "../../../actions/shared/france-captcha-popup"
import {isNullUndefined} from "../../../util"
import {nonUkNonHcpSearchTermOnchange} from "../../../actions/nonUkResidents/nonHcp/util/search_onchange"
import {linkedin} from 'react-icons-kit/fa/linkedin'
import {twitter} from 'react-icons-kit/fa/twitter'
import {document} from "browser-monads"
import { getNonUkClinicalTrialsDisclaimerPopupConfigs } from "../../../util/configs"
import { showNonUkResidentsNonHcpDisclaimerPopup } from "../../../actions/nonUkResidents/nonHcp"
import StraplineBanner from "../../_shared/topStrip/topstrip"

const NonUkResidentsNonHcpQuery = graphql`
query HeaderNonUkResidentsNonHcpQuery {
  allContentfulMenuItem {
    edges {
      node {
        slug
        position
        title
        description {
          content {
            content {
              value
            }
          }
        }
        spaceId
        default_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        cards_only_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        tabs_for_files_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        country_information_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        current_vacancies_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
      }
    }
  }
}
`

const Header = (props) => {
  const globalState = useSelector(state => state);
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const [menuItemIsActive, setmenuItemIsActive] = useState(null)
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setPrevScrollpos(currentScrollPos)
    setVisible((prevScrollpos === currentScrollPos))
  };

  const submitSearch = term => {
    navigate(`/NonUkResidents/Non-HCP/search?q=${term}`);
    dispatch(nonUkNonHcpSearchTermOnchange(""))
  }

  const searchOnchange = e => {
    dispatch(nonUkNonHcpSearchTermOnchange(e.target.value))
  }

  const searchKeyPress = e => {
    if(e.key === 'Enter'){
      submitSearch(globalState.nonUkHcpSearchTerm)
    }
  }

  const toggleClass = () => setIsActive(!isActive)

  const sortMenuItemByPosition = (a, b) => {
    if(parseInt(a.node.position) > parseInt(b.node.position)){
      return 1;
    }
    if(parseInt(a.node.position) < parseInt(b.node.position)){
      return -1
    }
    return 0;
  }

  const sortSubMenus = (a, b) => {
    if(parseInt(a.position) > parseInt(b.position)){
      return 1;
    }
    if(parseInt(a.position) < parseInt(b.position)){
      return -1
    }
    return 0;
  }

  const toggleFranceCaptchaPopup = (path) => {
    if(window.location.pathname === path) return;
    
    let actionConfig = { 
      show: true,
      path: path,
    }
    dispatch(showFranceCaptchaPopup(actionConfig))
  }

  const toggleClinicalTrialsDisclaimer = (path) => {
    if(window.location.pathname === path) return;
    
    let okCallback = () => {
      localStorage.setItem("nonukresidents-nonhcp-clinicaltrials", JSON.stringify({ disclaimerShown: true }));
      navigate(path);
    }
    let closeCallback = () => {}

    let popupConfig = getNonUkClinicalTrialsDisclaimerPopupConfigs(okCallback, closeCallback)

    dispatch(showNonUkResidentsNonHcpDisclaimerPopup(popupConfig))
  }

  const compileSortedSubMenus = (parentMenuSlug, allSubMenuTemplates, isMobile) => {
    
    let pages = [];

    for(let [templateName, submenuArray] of Object.entries(allSubMenuTemplates)){

      if(!isNullUndefined(submenuArray)){
        
        for(let submenu of submenuArray){
          if(submenu.parentMenu.slug === parentMenuSlug){
            let path = ""
            let callback = null;
            switch(templateName){
              case "currentVacanciesTemplateSubMenus":
                path = `/NonUkResidents/Non-HCP/Careers/${submenu.slug}`;
                break;
              case "tabsForFilesTemplateSubMenus":
                path = `/NonUkResidents/Non-HCP/${submenu.slug}`;
                if(submenu.slug === "france" && submenu.parentMenu.slug === "transparency"){
                  callback = () => toggleFranceCaptchaPopup(path);
                }
                break;
              case "defaultTemplateSubMenus":
                path = `/NonUkResidents/Non-HCP/${submenu.slug}`;
                if(submenu.slug === "clinical-trials" && submenu?.parentMenu?.slug === "research"){
                  callback = () => toggleClinicalTrialsDisclaimer(path);
                }
                break;
              default:
                path = `/NonUkResidents/Non-HCP/${submenu.slug}`;
                break;
            }
            pages.push({...submenu, pagePath: path, callback: callback})
          }
        }

      }

    }

    if(isMobile){
      return pages.sort(sortSubMenus).map(item => {
        const preventAutoNavigation = (e) => {
          if(!item.callback) return;
          e.preventDefault();
          item.callback();
        }
        return <Link key={item.slug} to={item.pagePath} onClick={preventAutoNavigation}><li className={`${styles.top_nav_btns}`}>{item.title}</li></Link>
      })
    }

    return pages.sort(sortSubMenus).map(item => {
      const preventAutoNavigation = (e) => {
        if(!item.callback) return;
        e.preventDefault();
        item.callback();
      }
      return <Link key={item.slug} to={item.pagePath} onClick={preventAutoNavigation}><li className={`${styles.top_nav_btns}`}>{item.title}</li></Link>
    })
  }


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  const query = useStaticQuery(NonUkResidentsNonHcpQuery);

  const menuItemEdges = query.allContentfulMenuItem.edges.filter(edge => edge.node.spaceId === process.env.GATSBY_NON_UK_RESIDENTS_NON_HCP_SPACE_ID);
  const setActiveMenuItem = id => {
    setmenuItemIsActive(id)
  }
  return ( 
    <div>
    
    <header className={`${styles.header} ${isActive ? "active"  : '' } ${classnames("header orange", {
      "nav_active": !visible
    })}`}>
       <div className={`strapbanner ${isActive ? "active" : ""}`}>
      <StraplineBanner />
      </div>
    
     {globalState.nonUkResidentsNonHcpDisclaimerPopup.show && <NonUkResidentsNonHcpDisclaimerPopup popupConfig={globalState.nonUkResidentsNonHcpDisclaimerPopup} />}
     {globalState.nonUkNonHcpApplyOnlinePopup.show && <NonUkResidentsNonHcpApplyOnlinePopup popupConfig={globalState.nonUkNonHcpApplyOnlinePopup} />}
      {globalState.nonUkNonHcpEmailFriendPopup.show && <NonUkResidentsNonHcpEmailFriendPopup popupConfig={globalState.nonUkNonHcpEmailFriendPopup} />}
      {globalState.franceCaptchaPopup.show && <FranceCaptchaPopup popupConfig={globalState.franceCaptchaPopup} />}

      <div className={`${styles.hide_on_mobile} ${styles.nav_wrap} row`}>
      <div className={styles.logo_section} >
      <Link to="/NonUkResidents/Non-HCP/home">
      <span className={`${styles.logo} sticky--logo`}></span>
      </Link>
     
      </div>
     
      <div className={`${styles.main_nav} main_nav_wrap`} >
          <div className={`${styles.top_section_nav} hide_on_sticky`}>
              <ul>
              <li><Link to="/NonUkResidents/Landing">Home</Link></li>
                <li><Link to="/NonUkResidents/Non-HCP/contact-us">Contact us</Link></li>
                {/* <li className={`search_content ${searchIsActive ? "active":""}`}>
                <button className="search__btn" aria-label="submit search"><Icon size={25} icon={search}  onClick={() => setSearchIsActive(!searchIsActive)}  /></button>
                <span className="search">
                 <input type="text" className="search__input" aria-label="search" placeholder="enter your search"  onChange={searchOnchange} value={globalState.nonUkNonHcpSearchTerm} onKeyPress={searchKeyPress}/>
                  <button className="search__btn" aria-label="submit search"  onClick={() => submitSearch(globalState.nonUkNonHcpSearchTerm)}>Search</button>
                </span>

                </li> */}
              </ul>
          </div>
          <ul style={{float: 'right'}}>
          
          {menuItemEdges.sort(sortMenuItemByPosition).map(edge => {
                let slug = edge.node.slug
                let title = edge.node.title;
                let description = edge.node.description.content[0].content[0].value;
            const content = edge.node.description.content[0].content
            if (content.length > 1) {
              let contentArr = []

              for (let i = 0; i < content.length; i++) {
                console.log(content[i], "object")
                contentArr.push(content?.[i]?.value)
              }
              description = contentArr.join("");
            
            }
            description = description?.replace(/_SUPERSCRIPTTM_/g, "<sup>&#174;</sup>");
            
                let allSubMenuTemplates = {
                  defaultTemplateSubMenus: edge.node.default_template,
                  cardsOnlyTemplateSubMenus: edge.node.cards_only_template,
                  tabsForFilesTemplateSubMenus: edge.node.tabs_for_files_template,
                  countryInformationTemplateSubMenus: edge.node.country_information_template,
                  currentVacanciesTemplateSubMenus: edge.node.current_vacancies_template,
                }
                
                return (
                  <li className={styles.primary_dropdown} key={slug}>
                    {title}
                  <div className={styles.dropdown_wrap}>
                    <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                          <div className={styles.first_circle}></div>
                          <div className={styles.second_circle}></div>
                          <div className={styles.third_circle}></div>
                          <div className={styles.fourth_circle}></div>
                          <div className={styles.fifth_circle}></div>
                       
                          
                        </div>
                        <div className={"col-12 col-md-12"}>
                        <ul >

                        {compileSortedSubMenus(slug, allSubMenuTemplates, false)}
                
                    </ul>
                        </div>
                        </div>
                    </div>
                  </div>
                    
                  </li>
                )
              })}
          
          </ul>
      </div>
    </div>
  



     {/* START MOBILE HEADER */}
    <div className={`${styles.hide_on_desktop} ${styles.mobile_header} ${isActive ? `${styles.active}` : ""} row`} >
      <div className={"col-6 col-md-3 col-lg-3" } style={{zIndex:'999999999', background:'#fff', padding:'15px'}}>
          <Link to="/NonUkResidents/Non-HCP/home">
          <span className={styles.logo}></span>
          </Link>
     
      </div>

      <div className={"col-6 col-md-9 col-lg-9"} style={{zIndex:'999999999', background:'#fff', padding:'20px'}}>
          <div className={styles.header_toggle}>
          {/* <a className={`search_content ${searchIsActive ? "active":""}`}>
            <button className="search__btn" aria-label="submit search"><Icon size={25} icon={search}  onClick={() => setSearchIsActive(!searchIsActive)}  /></button>
            <span className="search">
              <input type="text" className="search__input" aria-label="search" placeholder="enter your search"  onChange={searchOnchange} value={globalState.nonUkNonHcpSearchTerm} onKeyPress={searchKeyPress}/>
              <button className="search__btn" aria-label="submit search"  onClick={() => submitSearch(globalState.nonUkNonHcpSearchTerm)}>Search</button>
            </span>

            </a> */}
            <div className={styles.hamburger} onClick={() => toggleClass()} >
              <span className={styles.line}></span>
              <span className={styles.line}></span>
              <span className={styles.line}></span>
            </div>
          </div>
     
      </div>
   {/* START MOBILE NAV */}

   <div className={`mobile-menu hide_on_desktop ${isActive ? "active" : ""}` } >
      <div className="mobile-menu_wrap"> 

      <ul> 
        {menuItemEdges.sort(sortMenuItemByPosition).map((edge, id) => {
          let slug = edge.node.slug
          let title = edge.node.title;
          let description = edge.node.description.content[0].content[0].value;
          let allSubMenuTemplates = {
            defaultTemplateSubMenus: edge.node.default_template,
            cardsOnlyTemplateSubMenus: edge.node.cards_only_template,
            tabsForFilesTemplateSubMenus: edge.node.tabs_for_files_template,
            countryInformationTemplateSubMenus: edge.node.country_information_template,
            currentVacanciesTemplateSubMenus: edge.node.current_vacancies_template,
          }
            return (
              <li onClick={() => setActiveMenuItem(id)} className={` ${(menuItemIsActive === id && !isNullUndefined(menuItemIsActive)) ? "":""}`}>{title}
                    <ul >
                      <li className="parent_li">

                      {compileSortedSubMenus(slug, allSubMenuTemplates, true)}        

                      </li>
                    </ul>
                  </li>
              
            )
        })}
        <hr/>
     
        </ul>
        <ul className="mobile_nav_footer">
          <li className={styles.landing_page_menu_item}><Link to="/NonUkResidents/Landing" onClick={() => setIsActive(false)}>Home <span>|</span></Link></li>
          <li className={styles.landing_page_menu_item}><Link to="/NonUkResidents/Non-HCP/contact-us" onClick={() => setIsActive(false)}>Contact us</Link></li>
        
        </ul>
      </div>
    </div>

{/* END MOBILE NAV */}
    </div>

    {/* END MOBILE HEADER */}
    </header>
 

</div>
);
}

export default Header;