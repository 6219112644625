import axios from "axios"
import {config} from "../../../util/configs"

export function constructNonUkNonHcpContactFormEmailHtml(data){
    return (
        `
        <img src="${config.logoUrl}" title="Diurnal Logo" alt="Diurnal Logo" width="100" height="auto" style="display:block; padding-bottom: 15px;" >
            <h3>Here is the response from the submitted form:</h3>
            <div>
                <p><strong>Name</strong>: ${data.name}</p>
                <p><strong>Email</strong>: ${data.email}</p>
                <p><strong>Phone</strong>: (${data.phoneCode}) ${data.phone}</p> 
                <p><strong>Company</strong>: ${data.company}</p>
                <p><strong>Country</strong>: ${data.country}</p>
                <p><strong>How they heard about Diurnal</strong>: ${data.whoReferred}</p>
                <p><strong>Message</strong>: ${data.message}</p>
            </div>
        `
    )
}

export async function submitContactFormNonUkNonHcp(data){
    return await axios.post('/api/contact-form-mailer', data);
}

export function constructNonUkNonHcpApplicationFormEmailHtml(data){
    return (
        `
        <img src="${config.logoUrl}" title="Diurnal Logo" alt="Diurnal Logo" width="100" height="auto" style="display:block; padding-bottom: 15px;" >
            <h3>Here is the response from the submitted form:</h3>
            <div>
                <p><strong>Name</strong>: ${data.name}</p>
                <p><strong>Email</strong>: ${data.email}</p>
                <p><strong>Phone</strong>: ${data.phone}</p>
                <p><strong>Message / Cover Letter</strong>: ${data.coverLetter}</p>
            </div>
        `
    )
}

export function constructNonUkNonHcpEmailFriendFormEmailHtml(data){
    return (
        `
        <img src="${config.logoUrl}" title="Diurnal Logo" alt="Diurnal Logo" width="100" height="auto" style="display:block; padding-bottom: 15px;" >
        <h3>Hi ${data.recipientName}, here is an open vacancy you might be interested in at Diurnal, recommended by ${data.senderEmail}:</h3>
            <div>
                <p><strong>Job Title</strong>: ${data.jobTitle}</p>
                <p><strong>Job Type</strong>: ${data.jobType}</p>
                <p><strong>Country</strong>: ${data.country}</p>
                <p><strong>Location</strong>: ${data.location}</p>
                <a href="${data.jobLink}">Apply Now</a>
            </div>
        `
    )
}

export async function submitApplicationFormNonUkNonHcp(data){
    return await axios.post("/api/career-applications-mailer", {
        subject: data.subject,
        html: data.html,
        attachment: data.attachment
    })

}

export async function submitEmailFriendFormNonUkNonHcp(data){
    return await axios.post("/api/email-friend", data);
}